import emailjs from 'emailjs-com';
import React, { Component, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Project from './Project';

export default function ContactUs (){
  
const [formSent,changeFormSenT]=useState(false);
 /*  const sendEmail = (e) => {
    //e.preventDefault();

    emailjs.send('service_3uc1fmy', 'template_s8ys8tg', e.target, 'user_hYJPmdAZbDafkPxV7rUUZ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
        e.target.reset()
  } */

  return (

   /*  <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="name" />
      <label>Email</label>
      <input type="email" name="email" />
      <label>subject</label>
      <input type="text" name="subject" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */

<section className="testimonial  bg-s2 position-relative" id="contact">
<Project /> 
          
          <div className="background-transparent bg-cl5"></div>
          <div className="container d-lg-flex">                         
              <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
            

              <div className="flat-title t2">
                      <h4 className="sub-title mg-b13">Contact</h4>

                      <h2 className="title-section color-d11 mg-b20">Contact Form</h2>
                  </div>
              <div className="contact-section single-section">
                  <div className="row">
              
                              <div className="col-12 col-lg-8">
                              
                              
                              <Formik
                              initialValues={{
                                  name:'',
                                  email:'',
                                  subject:'',
                                  message:''
                              }}
                              validate={(values)=>{
                                  let errors={};


                                  if (!values.name) {
                                      errors.name = 'Name is Required';
                                    } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.name)) {
                                      errors.name = 'Invalid Name';
                                    }

                                  if (!values.email) {
                                      errors.email = 'Email is Required';
                                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                      errors.email = 'Enter a valid email (name@domain.com)';
                                    }
                                  
                                    if (!values.subject) {
                                      errors.subject = 'Subject is Required';
                                    } 

                                    if (!values.message) {
                                      errors.message = 'Required';
                                    } 

                                  return errors;
                              }}

                              /* onSubmit={sendEmail} */
                              onSubmit={(values,{resetForm})=>{
                                /* values.preventDefault(); */
                                 
                                 console.log(values);
                                 console.log('form antes sended');
                                 //send a aler with the confirmation 
                                  emailjs.send('service_3uc1fmy', 'template_s8ys8tg', values, 'user_hYJPmdAZbDafkPxV7rUUZ')
                                     .then((result) => {
                                         console.log(result.text);
                                     }, (error) => {
                                         console.log(error.text);
                                     }); 
                                     resetForm();   
                                     console.log('form despues sended');
                                     changeFormSenT(true);
                                     setTimeout(()=>changeFormSenT(false),5000)
                             }}
                              
                              >
                                  {({ errors})=>(

                                      <Form  className="contact-form" id="contact-form" >
                                     
                                     { formSent && <div div className="col-12 form-group">
                                          <Alert variant="primary">
                                          <Alert.Heading>Message Sent</Alert.Heading>
                                                        Thanks
                                            </Alert>
                                            </div>}
                                            { !formSent &&  <div className="row">
                                          
                                            
                                            


                                          <div className="col-12 form-group">
                                              <Field
                                              className="form-control" 
                                              id="contact-name" 
                                              type="text" 
                                              name="name" 
                                              placeholder="Name" 
                                              />
                                              <ErrorMessage name="name" component= {()=>( <div className="error color-d3">{errors.name}</div>)}/> 
                                            
                                              </div>

                                          <div className="col-12 form-group">
                                              <Field
                                              className="form-control" 
                                              id="contact-email" 
                                              type="email" 
                                              name="email" 
                                              placeholder="Email" 
                                              
                                              />
                                              <ErrorMessage name="email" component= {()=>( <div className="error color-d3">{errors.email}</div>)}/> 
                                              </div>


                                          <div className="col-12 form-group">
                                              <Field
                                              className="form-control" 
                                              id="contact-subject" 
                                              type="text" 
                                              name="subject" 
                                              placeholder="Subject" 
                                           
                                              />
                                              <ErrorMessage name="subject" component= {()=>( <div className="error color-d3">{errors.subject}</div>)}/> 
                                              </div>

                                          <div className="col-12 form-group form-message">
                                              <Field 
                                              name="message" 
                                              as= "textarea"
                                              className="form-control" 
                                              id="contact-message" 
                                              placeholder="Message" 
                                              rows="5" 
                                              />
                                           <ErrorMessage name="message" component= {()=>( <div className="error color-d3">{errors.message}</div>)}/> 
                                           </div>

                                           <button className="btn-contact-form" type="submit">Send Message</button>
                                          
                                                                        

                                          </div> }

                                          </Form>
                                  )}
                            
                              </Formik>

                              </div>
                  {/* 
                          <div className="col-12 col-lg-5">
                              <div className="contact-info">
                              <h2 className="title-section color-d11 mg-b">Thanks for getting in touch</h2>
                            
                              <ul className="list-unstyled list-info">
                           
                              <li>
                                  <div className="media align-items-center"><span className="info-icon"><i className="icon ion-md-map"></i></span>
                                  <div className="media-body info-details">
                                      <h6 className="info-type">Current Location</h6><span className="info-value">Brisbane, Queesland - Australia.</span>
                                  </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="media align-items-center"><span className="info-icon"><i className="icon ion-md-call"></i></span>
                                  <div className="media-body info-details">
                                      <h6 className="info-type">Current</h6><span className="info-value"><a href="tel:+441632967704">Cloud Architecture Student </a></span>
                                  </div>
                                  </div> 
                              </li>
                              </ul>
                              </div>
                          </div> */}
                  </div>
              </div>

              
            
           {/*  <div className="flat-spacer" data-desktop={4} data-mobile={0} data-smobile={0} /> */}

              </div>
           {/*    <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                  <div className="flat-spacer" data-desktop={0} data-mobile={100} data-smobile={100} /> */}
                                
              {/* </div> */}
          </div>               
      </section>

  
  );
};

