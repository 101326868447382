import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TopEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenttop : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.11s',
                    year: 'Canterbury Technical Institute',
                    title: 'Cloud Architecture',
                    text: 'Plans Design and implement highly-available cloud infrastructure, Disaster Recovery, web-scale cloud infrastructure, infrastructure with code'
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.9s',
                    year: ' Canterbury Technical Institute',
                    title: 'Software Delovepment',
                    text: 'Networks and Servers, Build user interface,  programming skills, Implement security for applications, Apply testing techniques for software development  '
                },

                {
                    id: 5,
                    classname: 'experience-box t3',
                    datawow: '0.3s',
                    year: 'University of Valle',
                    title: 'Electronic Engineer',
                    text: 'algorithms and programming, communications networks, PLC, microprocessors, electronic instrumentation, Digital signal processing, Electronic circuits, Robotics,  '
                },         
                
             
             
            ],
            contentbot : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.8s',
                    year: '2020 - Currently',
                    title: 'Technician -',
                    office: 'Australian Computer Traders (ACT)',
                    text: 'Hardware and Software repair in computer equipment (windows Sever, MacOS, Windows, Macrium, Hardware)'
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '1s',
                    year: '2018 - 2019',
                    title: 'Implementation, delivery and support Engineer -',
                    office: 'Huawei Colombia',
                    text: 'Implement, support and delivery projects engineer for value-added platforms (Suse 11, Oracle DB, SIP, BASH, Openstack, Servers)'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '1.2s',
                    year: '2015 - 2018',
                    title: 'IT Coordinator -',
                    office: 'APIX Specialized Logistics',
                    text: 'Manage the local and cloud infrastructure in the company (Windows Server, Linux, VOIP, SQL, PHP, Bootstrap, Network, WAL, LAN)'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="animate-element delay5 fadeInUp">
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contenttop.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <div className="s-info color-d11 mg-b11">{data.title}</div>
                                            <p className="color-d13">
                                            {data.text}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.contentbot.map(data => (
                            <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                <div className={data.classname}>
                                    <div className="box-inner">
                                    <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                    <div className="exp-wrap">
                                        <div className="s-info color-d11 mg-b11">{data.title} <Link to="#">{data.office}</Link></div>
                                        <p className="color-d13">
                                            {data.text} 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TopEducation;
