import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Icon } from '../home/about/index';
import { NumberCount } from './about/index';

class About extends Component {
    render() {
        return (
            <section className="about-me t2 s3 shape-am position-relative" id="about">
                <div className="section-icons">
                    <Icon />
                </div>
                <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-8 animate-element wow delay5 fadeInDown">
                        <div className="flat-title t2">
                            <h4 className="sub-title mg-b22">About Me</h4>
                            <h2 className="title-section color-d11 mg-b41">Hi, I am here to help your next project!</h2>
                            <div className="description">
                                <p className="color-d11 lt-sp01">
                                Experience in administration of local and cloud IT infrastructure, implementation and support of value-added applications on Linux servers in private cloud for telecommunications company. 
                                </p>
                                <p className="color-d11 lt-sp01">
                                Knowledge of technologies like Linux and Windows servers, networks, web apps, php, Laravel, vue.js node js, git, among others basic knowledge with Azure and AWS and working on improving my skills in CI/CD, Ansible, Docker, Kubernetes.
                                </p>
                               
                            </div>
                        </div>
                    </div>
                 {/*    <div className="fact">
                        <NumberCount />
                        <div className="btn-contact bg-s2 text-center">
                            <h4 className="title color-d11">Get a project? Let’s talk.</h4>
                            <Link to="#" className="email color-s2 color-d15">yourmail@email.com</Link>
                        </div>
                    </div> */}
                </div>
                </div>
                <div className="featured-post animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                <img src="images/section/51.png" alt="images" />
                </div>
            </section>
        );
    }
}

export default About;
