import React, { Component } from 'react';
import { ContentEducation } from '../home/education/index';
import {Link} from 'react-router-dom';

class Education extends Component {
    render() {
        return (
            <section className="flat-education s3 position-relative" id="resume">
                <div className="container">
                    <div className="flat-title t2 animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                        <h4 className="sub-title mg-b13">Educational qualification</h4>
                        <h2 className="title-section color-d11">Education &amp; Experience</h2>

                        <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <div className="fl-btn btn-general color-s2 btn-hv-border">
                                
                               {/*  <Link to="#" className="f-w500 color-d6 border-corner5 lt-sp01 text-two">Download Resume</Link> */}
                            </div>
                        </div>
                    </div>
                    <ContentEducation />
                </div>
            </section>
        );
    }
}

export default Education;
