import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <div className="tf-modal-content justify-content-end"> 
                <footer id="footer" className="footer footer-s4">
                    <div className="container">
                        <div id="footer-widget" className="footer-widget-s3 border-bottom-s2 position-relative">
                            <h3 className="widget-title larger lt-sp06 text-white text-center">Let’s try me!</h3>
                            <div className="site-list site-list-center text-center">
                                    <Link to={{pathname: "https://www.linkedin.com/in/ydanilomelo/"}} target="_blank" className="bg-s2"><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                    <Link to={{pathname: "https://github.com/yocemar"}} target="_blank" className="bg-s1"><i className="fa fa-github" aria-hidden="true" /></Link>
                                    <Link to={{pathname: "https://www.instagram.com/yocemar/" }} target="_blank" className="bg-s3"><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                    
                            </div>
                        </div>
                    </div>
                    <div id="bottom" className="bottom-s3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div  className="copyright lt-sp02">
                                        © Yocemar Danilo Melo, 2021 All rights reserved.
                                    </div>
                                    
                                </div>


                                <div className="col-lg-6">
                                    <div className="socials-list color-s2">
                                    <Link to={{pathname: "https://www.linkedin.com/in/ydanilomelo/"  }} target="_blank"><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                    <Link to={{pathname: "https://github.com/yocemar" }} target="_blank"><i className="fa fa-github" aria-hidden="true" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
