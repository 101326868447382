const menus = [


    {
        id: 1,
        tomenu: "#about",
        namemenu: "About"
    },
    {
        id: 2,
        tomenu: "#services",
        namemenu: "Skills"
    },
    {
        id: 3,
        tomenu: "#resume",
        namemenu: "Resume"
    },
    {
        id: 4,
        tomenu: "#portfolio",
        namemenu: "Portfolio"
    },
    {
        id: 5,
        tomenu: "#contact",
        namemenu: "Contact"
    },
    {
        id: 6,
        tomenu: "#interests",
        namemenu: "Interests"
    },
    
    
];



export default menus ;