import React, { Component } from 'react';
import {About, Banner, CaseStudy, Education, Footer, SkillBar, Header, Project, Specilizing, Testimonial, Contact, Contact2} from '../layouts/home/index';

class Home extends Component {
    render() {
        return (
            <div className="counter-scroll background-wrap-home fixed counter-scroll home has-one-page">               
                <div id="mobile-menu-overlay" />
                <Header />
                <div id="content" className="content">
                    <div className="homepage-personal">
                        <Banner />
                        <About />
                        <Specilizing />                                              
                        <Education />
                        <CaseStudy />
                        <Contact2/>                    
                        <Testimonial />                        
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
