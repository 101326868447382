
import Home from './Home';
import Blog from './Blog';


const routes = [

    { path: "/", component: Home},
    { path: "/blog", component: Blog},
/*     { path: "/home-04", component: Home01},
    { path: "/home-02", component: Home02},
    { path: "/home-03", component: Home03},  
    { path: "/blog", component: Blog},
    { path: "/blog-single", component: BlogSingle}, */
]

export default routes;