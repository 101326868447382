import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import ProgressBar  from 'react-bootstrap/ProgressBar';

class SkillBar extends Component {
    constructor(props){
        super(props);
                              
                this.state = {

            
            design : [
                {
                    id: 1,
                    iconbox: 'iconbox-s1 d-sm-flex align-items-center',
                    iconcolor: 'iconbox-icon color1',
                    spanicon: 'icon-designer',
                    title: 'Web Ui Design',
                    text01: "I'm a professional UX designer with 4 years of",
                    text02: 'experience. My main focus is on listening.'
                },
                {
                    id: 2,
                    iconbox: 'iconbox-s1 d-sm-flex justify-content-lg-center align-items-center mgl-iconbox-s1',
                    iconcolor: 'iconbox-icon color2 color1',
                    spanicon: 'icon-designer',
                    title: 'Mobile Ui Design',
                    text01: "I'm a professional UX designer with 4 years of",
                    text02: 'experience. My main focus is on listening.'
                },
                {
                    id: 3,
                    iconbox: 'iconbox-s1 d-sm-flex justify-content-lg-end align-items-center mgr-iconbox-s1',
                    iconcolor: 'iconbox-icon color3',
                    spanicon: 'icon-brand',
                    title: 'Branding Design',
                    text01: "I'm a professional UX designer with 4 years of",
                    text02: 'experience. My main focus is on listening.'
                },
            ]
        }
    }
    render() {
        return (
            <div className="section slide-personal-Intro-third slide-dark background-inner">
                <section className="specilizing-in s1" id="services">
                <div class="container">
                
                    </div>
                    <div className="featured-post animate-element wow delay5 fadeInRight" data-wow-delay="0.5s">
                        <img src="images/section/09.png" alt="images" />
                    </div>
                </section>
            </div>
        );
    }
}

export default SkillBar;
